// PRIVACY BOX
#onetrust-consent-sdk #onetrust-banner-sdk {
  background: '#FFFFFF' !important;
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25) !important;
}
#onetrust-banner-sdk #onetrust-close-btn-container {
  display: none !important;
}
#onetrust-consent-sdk #onetrust-policy-title,
#onetrust-consent-sdk #onetrust-policy-text,
#onetrust-consent-sdk .ot-b-addl-desc,
#onetrust-consent-sdk .ot-dpd-desc,
#onetrust-consent-sdk .ot-dpd-title,
#onetrust-consent-sdk #onetrust-policy-text *:not(.onetrust-vendors-list-handler),
#onetrust-consent-sdk .ot-dpd-desc *:not(.onetrust-vendors-list-handler),
#onetrust-consent-sdk #onetrust-banner-sdk #banner-options *,
#onetrust-banner-sdk .ot-cat-header {
  font-family: neue-haas-unica, sans-serif !important;
  font-size: 11px !important;
  color: var(--color-primary-light) !important;
  letter-spacing: 1px !important;
  outline: none !important;

  a:hover {
    color: var(--color-primary-accent) !important;
  }
}
#onetrust-policy-text {
  padding: 0 25px 0 25px !important;
}
#onetrust-consent-sdk #onetrust-accept-btn-handler,
#onetrust-banner-sdk #onetrust-reject-all-handler {
  background: var(--color-primary-light) !important;
  color: var(--color-primary-dark) !important;
  font-family: neue-haas-unica, sans-serif !important;
  font-size: 12px !important;
  padding: 10px !important;
  margin: 25px 0 0 0 !important;
  border-radius: 3px !important;
  opacity: 1 !important;
  &:hover {
    background: var(--color-primary-accent) !important;
  }
}
#onetrust-banner-sdk #onetrust-button-group-parent {
  padding: 0 25px 25px 25px !important;
}
#onetrust-consent-sdk #onetrust-banner-sdk {
  bottom: 10px !important;
  width: calc(100% - 20px) !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
}

// MQs
@media (min-width: 480px) {
  #onetrust-consent-sdk #onetrust-banner-sdk {
    max-width: 360px !important;
  }
}
@media (min-width: 960px) {
  #onetrust-consent-sdk #onetrust-banner-sdk {
    bottom: 0 !important;
    border-radius: 3px 3px 0 0 !important;
    left: 30px !important;
    transform: translateX(0) !important;
  }
}